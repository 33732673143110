const Surprised = () => {
  return(
    <svg id="emoji" width={70} height={70} viewBox="0 0 72 72">
      <g id="color">
        <circle cx="36" cy="36" r="23" fill="#FCEA2B"/>
        <ellipse cx="36" cy="48.395" rx="5.2452" ry="6.5383" fill="#FFFFFF"/>
      </g>
      <g id="hair"/>
      <g id="skin"/>
      <g id="skin-shadow"/>
      <g id="line">
        <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M19.2007,57.2743"/>
        <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M19.2008,57.2742"/>
        <circle cx="36" cy="36" r="23" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
        <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M20.6948,27.2002c0.7207-1.3857,1.9278-2.4541,3.3907-3c1.4052-0.7002,3.0205-0.8486,4.5302-0.4209"/>
        <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M51.3052,27.2002c-1.582-2.7724-4.8037-4.1699-7.9092-3.4306"/>
        <ellipse cx="36" cy="48.395" rx="5.2452" ry="6.5383" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
        <path d="M30,35c0,1.6568-1.3448,3-3,3c-1.6553,0-3-1.3433-3-3c0-1.6552,1.3447-3,3-3C28.6552,32,30,33.3448,30,35"/>
        <path d="M48,35c0,1.6568-1.3447,3-3,3s-3-1.3433-3-3c0-1.6552,1.3447-3,3-3S48,33.3448,48,35"/>
      </g>
    </svg>
  );
};

export default Surprised;