const Fearful = () => {
  return(
    <svg id="emoji" width={70} height={70} viewBox="0 0 72 72">
      <g id="line-supplement">
        <path d="M42.8472,45.6583c0-2.592-3.834-4.694-6.869-4.694s-6.869,2.101-6.869,4.694,3.659.9248,6.694.9248S42.8472,48.25,42.8472,45.6583Z"/>
      </g>
      <g id="color">
        <path fill="#fcea2b" d="M35.9772,12.9583a23,23,0,1,0,23,23A23.026,23.026,0,0,0,35.9772,12.9583Z"/>
        <path fill="#92d3f5" d="M35.9772,24.7689A23.0392,23.0392,0,0,1,58.2053,41.8636a23,23,0,1,0-44.4561,0A23.0389,23.0389,0,0,1,35.9772,24.7689Z"/>
        <path d="M42.8472,45.6583c0-2.592-3.834-4.694-6.869-4.694s-6.869,2.101-6.869,4.694,3.659.9248,6.694.9248S42.8472,48.25,42.8472,45.6583Z"/>
      </g>
      <g id="line">
        <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M29.3564,22.669a8.44,8.44,0,0,0-9.0659,4.4981"/>
        <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M51.664,27.1671a8.44,8.44,0,0,0-9.0659-4.4981"/>
        <circle cx="35.9772" cy="35.9583" r="23" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2"/>
        <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M42.8472,45.6583c0-2.592-3.834-4.694-6.869-4.694s-6.869,2.101-6.869,4.694,3.659.9248,6.694.9248S42.8472,48.25,42.8472,45.6583Z"/>
        <path d="M29.9772,32.9583a3,3,0,1,1-3-3,3.0011,3.0011,0,0,1,3,3"/>
        <path d="M47.9772,32.9583a3,3,0,1,1-3-3,3.0011,3.0011,0,0,1,3,3"/>
      </g>
    </svg>
  );
};

export default Fearful;